

const railsEnv = {"RAILS_ENV":"production","API_HOST":"https://mit.gratisbyggetilbud.dk","GBT_DOMAIN":"mit.gbt.dk","GOOGLE_TAG_MANAGER_ID":"GTM-PLT2QQN","IN_MOBILE_PHONE_NUMBER":"+4554546373","IN_MOBILE_PRIORITY_PHONE_NUMBER":"+4554540078","MY_DOMAIN":"mit.gratisbyggetilbud.dk","TEST_GET_OFFERS_WIDGET":null}
const env = {}

for (const key in railsEnv) {
  env[key] = window[key] || railsEnv[key]
}

export default env
